/* Fuentes de Hyundai Front */

@font-face {
  font-family: "HyundaiSansHead-Regular";
  src: url("../fonts/HyundaiSansHead-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "HyundaiSansHead-Bold";
  src: url("../fonts/HyundaiSansHead-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "HyundaiSansHead-Medium";
  src: url("../fonts/HyundaiSansHead-Medium.otf");
  font-display: swap;
}

/************FUENTES DE HYUNDAI***********/

/* This stylesheet generated by Transfonter (https://transfonter.org) on February 27, 2018 11:28 PM */

@font-face {
  font-family: 'HyundaiSansHeadOffice-Bold';
  src: url('HyundaiSansHeadOffice-Bold.eot');
  src: local('Hyundai Sans Head Office Bold'), local('HyundaiSansHeadOffice-Bold'), url('HyundaiSansHeadOffice-Bold.eot?#iefix') format('embedded-opentype'), url('HyundaiSansHeadOffice-Bold.woff') format('woff'), url('HyundaiSansHeadOffice-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('HyundaiSansHeadOffice-Light.eot');
  src: local('Hyundai Sans Head Office Light'), local('HyundaiSansHeadOffice-Light'), url('HyundaiSansHeadOffice-Light.eot?#iefix') format('embedded-opentype'), url('HyundaiSansHeadOffice-Light.woff') format('woff'), url('HyundaiSansHeadOffice-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('HyundaiSansHeadOffice-Medium.eot');
  src: local('Hyundai Sans Head Office Medium'), local('HyundaiSansHeadOffice-Medium'), url('HyundaiSansHeadOffice-Medium.eot?#iefix') format('embedded-opentype'), url('HyundaiSansHeadOffice-Medium.woff') format('woff'), url('HyundaiSansHeadOffice-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  src: url('HyundaiSansHeadOffice-Regular.eot');
  src: local('Hyundai Sans Head Office'), local('HyundaiSansHeadOffice-Regular'), url('HyundaiSansHeadOffice-Regular.eot?#iefix') format('embedded-opentype'), url('HyundaiSansHeadOffice-Regular.woff') format('woff'), url('HyundaiSansHeadOffice-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('HyundaiSansTextOffice-Regular.eot');
  src: local('Hyundai Sans Text Office'), local('HyundaiSansTextOffice-Regular'), url('HyundaiSansTextOffice-Regular.eot?#iefix') format('embedded-opentype'), url('HyundaiSansTextOffice-Regular.woff') format('woff'), url('HyundaiSansTextOffice-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('HyundaiSansTextOffice-BoldItalic.eot');
  src: local('Hyundai Sans Text Office Bold Italic'), local('HyundaiSansTextOffice-BoldItalic'), url('HyundaiSansTextOffice-BoldItalic.eot?#iefix') format('embedded-opentype'), url('HyundaiSansTextOffice-BoldItalic.woff') format('woff'), url('HyundaiSansTextOffice-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office Medium';
  src: url('HyundaiSansTextOffice-Medium.eot');
  src: local('Hyundai Sans Text Office Medium'), local('HyundaiSansTextOffice-Medium'), url('HyundaiSansTextOffice-Medium.eot?#iefix') format('embedded-opentype'), url('HyundaiSansTextOffice-Medium.woff') format('woff'), url('HyundaiSansTextOffice-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('HyundaiSansTextOffice-Bold.eot');
  src: local('Hyundai Sans Text Office Bold'), local('HyundaiSansTextOffice-Bold'), url('HyundaiSansTextOffice-Bold.eot?#iefix') format('embedded-opentype'), url('HyundaiSansTextOffice-Bold.woff') format('woff'), url('HyundaiSansTextOffice-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('HyundaiSansTextOffice-Italic.eot');
  src: local('Hyundai Sans Text Office Italic'), local('HyundaiSansTextOffice-Italic'), url('HyundaiSansTextOffice-Italic.eot?#iefix') format('embedded-opentype'), url('HyundaiSansTextOffice-Italic.woff') format('woff'), url('HyundaiSansTextOffice-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Hyundai Sans Text Office';
  src: url('HyundaiSansTextOffice-MediumItalic.eot');
  src: local('Hyundai Sans Text Office Medium Italic'), local('HyundaiSansTextOffice-MediumItalic'), url('HyundaiSansTextOffice-MediumItalic.eot?#iefix') format('embedded-opentype'), url('HyundaiSansTextOffice-MediumItalic.woff') format('woff'), url('HyundaiSansTextOffice-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/******************************************/

.estrella-destacada {
  width: 62px;
  height: auto;
  z-index: 3;
  position: absolute;
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: 4px solid #f5f4f3!important;
}

button {
  outline: none !important;
}

button::-moz-focus-inner {
  border: 0 !important;
}

.form-control:focus {
  border-color: #52BDFD !important;
  box-shadow: 0 0 0 0 #52BDFD !important;
}

.imagen-sin {
  max-width: 200px;
  width: 150px;
}

.image-recinto {
  border-radius: 1.2rem 1.2rem 1.2rem 1.2rem !important;
}

.boton {
  color: #ffffff;
  padding: 0.375rem 0.75rem;
  border-radius: 20px;
  border: 0px solid transparent;
  font-family: "HyundaiSansHeadOffice-Bold";
  text-align: center;
  font-size: 1rem;
  min-width: 8rem;
  max-width: 16rem;
}

.boton-vigencia {
  color: #ffffff;
  padding: 0.2rem 0.75rem;
  border-radius: 20px;
  border: 1px solid transparent;
  font-family: "HelveticaNeuBold";
  text-align: center;
  font-size: 0.8rem;
}

.boton.pink:focus {
  border-color: #e00070 !important;
  box-shadow: 0 0 0 0.2rem rgba(224, 0, 112, 0.4) !important;
}

.pink {
  background-color: #e00070;
}

.orange {
  background-color: #EB541C;
}

.boton.green:focus {
  border-color: #207245 !important;
  box-shadow: 0 0 0 0.2rem rgba(32, 114, 69, 0.4) !important;
}

.green {
  background-color: #207245;
}

.pointer:hover {
  cursor: pointer;
}

.mini.pink:hover {
  cursor: pointer;
  background-color: rgba(224, 0, 112, 0.6);
}

.boton.pink:hover {
  background-color: rgba(224, 0, 112, 0.6);
}

.boton-vigencia.pink:hover {
  background-color: rgba(224, 0, 112, 0.6);
}

.gray {
  background-color: #818181;
}

.purple {
  background-color: #582eb4;
}

.azul-hyundai {
  background-color: #3A739C;
}

.boton.purple:focus {
  border-color: #582eb4 !important;
  box-shadow: 0 0 0 0.2rem rgba(88, 46, 180, 0.4) !important;
}

.lila {
  background-color: #8c66e0;
}

.h-20 {
  height: 20% !important;
}

.mini.purple:hover {
  cursor: pointer;
  background-color: rgba(88, 46, 180, 0.6);
}

.boton.purple:hover {
  background-color: rgba(88, 46, 180, 0.6);
}

.container-title {
  height: 3rem;
  border-radius: 1rem !important;
}

.botton-unstyled {
  border: 1px solid transparent;
  background-color: transparent;
}

.grupo {
  padding: 0.375rem 0.75rem;
  border-radius: 20px;
  border: 1px solid #707070;
  font-family: "HelveticaNeuMedium";
  text-align: center;
  font-size: 0.875rem;
  min-width: 8rem;
}

.grupo:hover {
  border: 3.5px solid rgba(112, 112, 112, 0.4) !important;
  cursor: pointer;
  padding: 0.2rem 0.75rem !important;
}

.selected {
  color: white;
  background-color: #8c66e0;
}

.noSelected {
  color: #482c86;
  background-color: #f2f2f2;
}

.container-title {
  height: 3rem;
  border-radius: 1rem !important;
}

/* .hr {
  width: 100%;
  color: #707070;
  height: 0.2px;
  background-color: #707070;
} */

.input {
  border-radius: 1rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
}

.span-input {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
}

.input-span {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
}

.input-area {
  border-radius: 1rem !important;
}

.inputDescripcion {
  border-radius: 1rem !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
}

.right {
  text-align: right !important;
  justify-items: right !important;
}

.grey-back {
  background-color: #f2f2f2 !important;
}

.light-grey-back {
  background-color: rgb(249, 248, 248) !important;
}

.purple-back {
  background-color: #f7f4ff !important;
}

.dark-grey-back {
  background-color: #818181 !important;
}

.white-back {
  background-color: #ffffff !important;
}

.carta {
  max-width: 18rem;
  border: 1px solid transparent !important;
  border-radius: 20px !important;
}

/* .carta:hover {
  max-width: 17.7rem;
  border: 0.3rem solid rgba(72, 44, 134, 0.4) !important;
} */

.carta-horizontal {
  max-width: 40rem;
  border: 1px solid transparent;
  border-radius: 20px !important;
}

/* .carta-horizontal:hover {
  max-width: 29.7rem;
  border: 0.3rem solid rgba(72, 44, 134, 0.4) !important;
} */

.borde-t {
  border: 1px solid transparent !important;
}

.head-card {
  border: 0px solid transparent;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.foot-card {
  border: 0px solid transparent !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.carta-title {
  text-transform: uppercase;
  text-align: center;
}

.card-text {
  font-size: 15px;
  text-align: center;
  font-family: "HelveticaNeuMedium";
  color: #291957;
}

.card-text-grey {
  font-size: 15px;
  text-align: center;
  font-family: "HelveticaNeuMedium";
  color: #818181;
}

.card-text-pink {
  font-size: 15px;
  text-align: center;
  font-family: "HelveticaNeuMedium";
  color: #e00070;
}

.title-page {
  font-family: "Hyundai Sans Head Office";
  color: #291957;
  font-size: 20px;
}

.title-grey {
  font-family: "Hyundai Sans Head Office" !important;
  color: #818181;
  font-size: 20px;
}

.subtitle {
  font-family: "Hyundai Sans Head";
  color: #010001;
  font-size: 20px;
}

.icono {
  height: 35px;
  width: 35px;
}

img .icono:focus {
  border-color: #efefef !important;
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 210, 0.4) !important;
}

.icono-click:hover {
  cursor: pointer;
  height: 33px;
  width: 33px;
  border-radius: 17px;
}

.notificacion-icono {
  height: 35px;
  width: 35px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.editar:hover {
  border: 3px solid rgba(140, 102, 224, 0.4) !important;
}

.eliminar:hover {
  border: 3px solid rgba(131, 112, 172, 0.4) !important;
}

.visualizar:hover {
  border: 3px solid rgba(72, 44, 134, 0.4) !important;
}

.imagen-carta {
  display: block;
  width: 100%;
  height: auto;
}

.imagen-form {
  max-width: 320px !important;
}

.checkboxText {
  font-size: 16px;
  font-family: "HelveticaNeuMedium";
  color: #000000 !important;
}

.imagen-form {
  max-width: 320px !important;
}

.custom-file-label::after {
  font-size: 0.875rem !important;
  height: calc(1.4em + 0.5rem + 2px) !important;
  content: "Buscar" !important;
  background-color: #bcbcbc !important;
  padding: 0.25rem 1rem !important;
  color: black !important;
  border-left: inherit;
  border-radius: 1rem 1rem 1rem 1rem !important;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Seleccionar" !important;
}

.w-20 {
  width: 20%;
}

.pad-1 {
  padding-right: 0.2rem !important;
}

.cCargando {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 9999;
  transition: width 2s;
  -webkit-transition: width 2s;
  cursor: progress;
}

.cCargandoImg {
  cursor: progress;
  display: flex;
  min-height: 100vh;
  min-width: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  color: #808080;
  min-width: 50%;
  min-height: 100%;
}

.GifPantallaCarga {
  width: 200px;
  height: auto;
  opacity: 70%;
}

.oculto {
  display: none;
}

.titulos-fondo {
  background-color: #f7f4ff;
  border-radius: 1rem;
}

/*Michael*/

.sidebar {
  background-color: #3A739C;
  height: 100vh;
  width: 100%;
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  display: block !important;
  z-index: 1 !important;
  color: #fff !important;
  font-weight: 200 !important;
  background-size: cover !important;
  background-position: center center !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.siderbarLi {
  background: white!important;
  border-radius: 35px 0px 0px 35px!important;
}

.img-sidebar {
  max-width: 150px !important;
}

@media (min-width: 0px) and (max-width: 770px) {
  .sidebar {
    background-color: #411777;
    padding-bottom: 30px;
    width: 100%;
    position: relative !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    display: block !important;
    z-index: 1 !important;
    color: #fff !important;
    font-weight: 200 !important;
    background-size: cover !important;
    background-position: center center !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.grandWrapper {
  position: relative;
  top: 0;
  height: 100vh;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.ContenidoLateral {
  transition-property: top, bottom;
  transition-duration: 0.2s, 0.2s;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
  position: relative;
  float: right;
  width: calc(100% - 260px);
  margin-left: 16.666667% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}

.icono-sidebar {
  height: 35px;
  width: auto;
}

.item-sidebar {
  height: 60px;
}

.item-sidebar:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.texto-white {
  color: white;
  font-family: "HelveticaNeuMedium";
  font-weight: normal !important;
}

.texto-hyundai {
  color: white;
  font-family: "HyundaiSansHeadOffice-Bold";
}

.texto-sm {
  font-size: small;
}

.linkSidebar {
  text-decoration: none !important;
}

.text-white:hover {
  cursor: pointer;
}

.hr-carta {
  width: 100%;
  color: #707070;
  height: 0.5px !important;
  background-color: #707070;
}

.border-bottom {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.border-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.circulo-zona {
  height: 35px;
  width: 35px;
  border-radius: 18px;
}

.foot-card.foot-horizontal {
  width: 100%;
  position: absolute;
  border-top: 1px solid rgb(228, 228, 228) !important;
  bottom: 0px !important;
}

.foot-card.foot-horizontal-vaca {
  /* width: 100%;
  position: absolute; */
  /* border-top: 1px solid rgb(228, 228, 228) !important; */
  bottom: 3.5rem !important;
}

.circle-editar {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #ffffff;
}

.mb-general {
  margin-bottom: 100px;
}

@media (min-width: 576px) {
  .container-general-page {
    margin: 0px;
    margin-top: 110px;
  }
}

.my-general {
  margin: 100px 0;
}

@media (max-width: 575px) {
  .container-general-page {
    margin: 0px;
    margin-top: 45px;
  }
}

.btn-primario {
  background-color: #35719e!important;
  font-family: "HyundaiSansHead-Medium"!important;
  color: #FFFFFF!important;
  border: 2px solid #35719e!important;
  border-radius: 0!important;
  min-height: 35px!important;
}

.btn-primario:focus {
  background-color: #35719e!important;
  font-family: "HyundaiSansHead-Medium"!important;
  color: #FFFFFF!important;
  ;
  border: 2px solid #35719e !important;
  ;
  border-radius: 0!important;
}

.btn-primario[disabled], .btn-primario[disabled]:hover, .btn-primario[disabled]:focus, .btn-primario[disabled]:active {
  color: white!important;
  background: #a2a2a2!important;
  border-color: #a2a2a2!important;
}

.btn-secundario {
  background-color: #FFFFFF!important;
  font-family: "HyundaiSansHead-Medium"!important;
  color: #000000!important;
  border: 2px solid #35719e!important;
  opacity: 1!important;
  border-radius: 0!important;
  min-height: 35px!important;
}

.btn-secundario:focus {
  background-color: #FFFFFF!important;
  font-family: "HyundaiSansHead-Medium"!important;
  color: #000000!important;
  border: 2px solid #35719e!important;
  opacity: 1!important;
  border-radius: 0!important;
}

.btn-primario:hover {
  background-color: #FFFFFF!important;
  font-family: "HyundaiSansHead-Medium"!important;
  color: #000000!important;
  border: 2px solid #35719e!important;
  opacity: 1!important;
}

.btn-secundario:hover {
  background-color: #35719e!important;
  font-family: "HyundaiSansHead-Medium"!important;
  color: #FFFFFF!important;
  border: 2px solid #35719e!important;
}

.color-btn-floating {
  color: #000000!important;
}

.font-floating-btn {
  /* font-size: 9px; */
  font-size: 10px;
}

.icon-floating {
  max-width: 30px;
  max-height: 30px;
}

.min-h-floating {
  min-height: 60px;
}

.floating-shadow {
  border-top: 1px solid #03306C;
}

.floating-border-right {
  border-right: 1px solid #d9dee2;
}

.bg-cotizar-floating {
  background-color: #35719e;
}

.slider-multiple .slick-dots li button::before {
  color: #35719e;
}

.slider-multiple {
  padding-left: 40px;
  padding-right: 40px;
}

.slider-multiple .slick-prev::after {
  content: url(../img/slider/left.svg);
  font-size: 40px;
  color: #35719e;
}

.slider-multiple .slick-arrow {
  height: 50px;
  width: 40px;
}

.slider-multiple .slick-next::after {
  content: url(../img/slider/right.svg);
  font-size: 40px;
  color: #35719e;
}

.slider-multiple .slick-prev:before {
  content: '';
}

.slider-multiple .slick-next:before {
  content: '';
}

.slider-multiple .slick-dots li button {
  width: 35px!important;
}

.slider-multiple .slick-dots li button::before {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 40px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  border-top: 1px solid #35719e!important;
  border-bottom: 1px solid #35719e!important;
  opacity: .5;
  content: '';
  background-color: #35719e!important;
}

.slider-multiple .slick-active button::before {
  opacity: 1!important;
}

.slider-multiple .slick-dots li {
  width: 50px;
}

@media (max-width: 767px) {
  .slider-multiple .slick-dots li button::before {
    width: 20px;
    height: 2px;
  }
  .slider-multiple .slick-dots li {
    width: 20px;
  }
}

.tab-hyundai .ant-tabs-ink-bar {
  height: 10px!important;
  background-color: #35719e;
}

.tab-hyundai .ant-tabs-nav::before {
  border-bottom: 2px solid #35719e!important;
}

.carousel-primario .carousel-control-prev-icon {
  height: 100px;
  width: 100px;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-primario .carousel-control-next-icon {
  height: 100px;
  width: 100px;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-primario .carousel-control-next .carousel-control-next-icon:after {
  content: url(../img/slider/right.svg);
  font-size: 50px;
  color: #35719e;
}

.carousel-primario .carousel-control-prev .carousel-control-prev-icon:after {
  content: url(../img/slider/left.svg);
  font-size: 50px;
  color: #35719e;
}

.carousel-primario .carousel-indicators li {
  border-top-width: 1px;
  border-bottom-width: 1px;
  background: #94b3cc;
  width: 40px;
}

.carousel-primario .carousel-indicators .active {
  background: #35719e;
}

.color-float {
  color: #000000!important;
}

.error-formulario {
  border: 1px solid red!important;
}

.commercialBlue {
  color: #35719e
}

.font-politica {
  font-size: 18px
}

.font-subsecciones a {
  color: #35719e;
  text-decoration: underline;
}

.font-subsecciones h4 {
  font-size: 1.125rem
}

.font-subsecciones h3 {
  font-size: 1.5rem  
}

.font-subsecciones h2 {
  font-size: 1.75rem  
}

.position-title {
  top: 15%;
  left: 5%;
  color: white!important;
  position: absolute;
}

.text-shadow-title {
  text-shadow: 0 0 6px black;
}

@media (max-width:1300px) {
  .position-subtitle {
    top: calc(100% - 60%);
    left: calc(100% - 95%);
    color: white!important;
    ;
    position: absolute;
  }
  .text-shadow-title {
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: #000;
  }
}

@media (min-width: 770px) and (max-width: 1600px) {
  h1 {
    font-size: 2.25rem !important;
  }
  h5 {
    font-size: 1.15rem !important;
  }
  .f-18 {
    font-size: 17px !important;
  }
  .img-caracteristicas {
    width: 25px !important;
    height: 20px !important;
  }
}

.iva {
  font-size: 12px!important;
}

.tab-hyundai .ant-tabs-nav-operations {
  margin-top: 0!important;
  right: 0 !important;
  left: auto !important;
  width: auto!important;
  padding: 16px 0;
  top: 0!important;
}

.tab-hyundai .ant-tabs-nav {
  margin-bottom: 0px!important;
}

.hyundai-select .ant-select-selector {
  border: 1px solid #35719e !important;
  opacity: 1;
  border-radius: 5px!important;
}

.hyundai-select .ant-select-arrow {
  color: #35719e;
  opacity: 1;
  font-size: 12px;
}

/* NUMEROS DEL PAGINADOR/PAGINATION */

.paginador-hyundai {
  font-size: 18px !important;
  font-weight: 400;
}

.paginador-hyundai .ant-pagination-item-active {
  background-color: #35719e;
  border-radius: 5px;
  border-color: #35719e;
}

.paginador-hyundai .ant-pagination-item {
  border-radius: 5px;
  border-color: #939393;
}

.paginador-hyundai .ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.paginador-hyundai .ant-pagination-prev .ant-pagination-item-link {
  border: none;
}

.paginador-hyundai .ant-pagination-item-active a {
  color: #FFFFFF!important;
}

/* CENTRADO DE DATOS CON UN MARGEN LEFT ACORDE A INVISION */

.hyundai-centrado {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 15px;
}

.carousel-primario .carousel-control-prev {
  justify-content: start;
  margin-left: 30px;
}

.carousel-primario .carousel-control-next {
  justify-content: flex-end;
  margin-right: 30px;
}

.slider-flechas .carousel-control-prev {
  justify-content: start;
  margin-left: 30px;
}

.slider-flechas .carousel-control-next {
  justify-content: flex-end;
  margin-right: 30px;
}

@media (max-width: 768px) {
  .carousel-primario .carousel-control-prev {
    justify-content: start;
    margin-left: 5px;
  }
  .carousel-primario .carousel-control-next {
    justify-content: flex-end;
    margin-right: 5px;
  }
  .position-title {
    top: 7%;
  }
}

@media (max-width:767px) {
  .slider-a .carousel-control-prev-icon {
    width: 15px;
    height: 15px;
  }
  .slider-a .carousel-control-next-icon {
    width: 15px;
    height: 15px;
  }
  .slider-a .carousel-control-prev {
    justify-content: start;
    margin-left: 5px;
  }
  .slider-a .carousel-control-next {
    justify-content: flex-end;
    margin-right: 5px;
  }
  .slider-flechas .carousel-control-prev-icon {
    width: 15px;
    height: 15px;
  }
  .slider-flechas .carousel-control-next-icon {
    width: 15px;
    height: 15px;
  }
  .slider-flechas .carousel-control-prev {
    justify-content: start;
    margin-left: 5px;
  }
  .slider-flechas .carousel-control-next {
    justify-content: flex-end;
    margin-right: 5px;
  }
}

.my-ficha {
  margin: 90px 0;
}

.f-18 {
  font-size: 18px;
}

@media (max-width: 767px) {
  .f-responsive-16 {
    font-size: 16px!important;
  }
  .f-responsive-14 {
    font-size: 14px!important;
  }
  .f-responsive-12 {
    font-size: 12px!important;
  }
  .f-responsive-11 {
    font-size: 11px!important;
  }
  .icono-ubicacion {
    width: 30px !important;
    height: auto !important;
  }
  .caracteristicas-img-responsive {
    width: auto!important;
    height: 30px !important;
  }
  .f-18 {
    font-size: 12px !important;
  }
  .my-ficha {
    margin: 40px 0 20px;
  }
  .fixedOnMobile1 {
    position: fixed !important;
    z-index: 2 !important;
    /* top: 88px; */
    background-color: #FFFFFF;
  }
  .fixedOnMobile2 {
    position: fixed !important;
    z-index: 2 !important;
    top: 140px;
    background-color: #FFFFFF;
  }
  .paraFixedMobile {
    /* height: 273px; */
  }
}

.btn-unidad-ficha-left {
  border-top: 1px solid #7D9FBA;
  border-bottom: 1px solid #7D9FBA;
  border-left: 1px solid #7D9FBA;
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 30px;
  height: 30px;
  background-color: #FFFFFF;
}

.f-18 {
  font-size: 18px;
}

.font-btn {
  font-size: 17px!important;
}

.img-caracteristicas {
  width: 30px;
  height: 25px;
}

.f-center-left {
  text-align: center;
}

#tab-vehiculo .ant-tabs-nav::before {
  border-bottom: none!important;
}

.tab-vehiculo .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
  background-color: #35719e;
  height: 10px!important;
}

.tab-vehiculo .ant-tabs-nav {
  background-color: #ffffff;
}

.tab-vehiculo .ant-tabs-nav-operations {
  margin-top: 0!important;
  right: 0 !important;
  left: auto !important;
  width: auto!important;
  padding: 16px 0;
  top: 0!important;
}

.swal2-styled.swal2-confirm {
  background-color: #35719e!important;
  font-family: "HyundaiSansHead-Medium"!important;
  font-size: 18px!important;
  color: #FFFFFF!important;
  border: 2px solid #35719e!important;
  border-radius: 0!important;
  min-height: 35px!important;
}

.swal2-cancel.swal2-styled {
  background-color: #ffffff!important;
  font-family: "HyundaiSansHead-Medium"!important;
  font-size: 18px!important;
  color: black!important;
  border: 2px solid #35719e!important;
  border-radius: 0!important;
  min-height: 35px!important;
}

@media (max-width: 767px) {
  .tab-vehiculo .ant-tabs-nav {
    background-color: #35719e;
    margin-bottom: 20px!important;
  }
  .color-mobile {
    color: #FFFFFF;
  }
  .tab-vehiculo .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
    background-color: #FFFFFF!important;
    height: 4px!important;
  }
  .swal2-title {
    font-size: 18px!important;
  }
  .swal2-content {
    font-size: 14px!important;
  }
  .swal2-styled.swal2-confirm {
    background-color: #35719e!important;
    font-family: "HyundaiSansHead-Medium"!important;
    font-size: 10px!important;
    color: #FFFFFF!important;
    border: 1px solid #35719e!important;
    border-radius: 0!important;
    min-height: auto!important;
    height: auto !important;
    padding: 2px 15px !important;
  }
  .swal2-cancel.swal2-styled {
    background-color: #ffffff!important;
    font-family: "HyundaiSansHead-Medium"!important;
    font-size: 10px!important;
    color: black!important;
    border: 2px solid #35719e!important;
    border-radius: 0!important;
    padding: 2px 15px !important;
    min-height: auto!important;
  }
}

@media (max-width: 768px) {
  .mb-general {
    margin-bottom: 40px;
  }
  .btn-primario {
    background-color: #35719e!important;
    font-family: "HyundaiSansHead-Medium"!important;
    color: #FFFFFF!important;
    border: 1px solid #35719e!important;
    border-radius: 0!important;
    min-height: auto!important;
    height: auto !important;
    padding: 2px 15px !important;
  }
  .btn-secundario {
    background-color: #FFFFFF!important;
    font-family: "HyundaiSansHead-Medium"!important;
    color: #000000!important;
    border: 1px solid #35719e!important;
    opacity: 1!important;
    border-radius: 0!important;
    min-height: auto!important;
    height: auto !important;
    padding: 2px 15px !important;
  }
  .font-btn {
    font-size: 10px!important;
  }
  .f-responsive {
    font-size: 12px!important;
  }
  .f-responsive-14 {
    font-size: 14px!important;
  }
  .f-responsive-16 {
    font-size: 16px!important;
  }
  .f-responsive-18 {
    font-size: 18px!important;
  }
  .f-responsive-10 {
    font-size: 10px!important;
  }
  .f-responsive-8 {
    font-size: 9px!important;
  }
  .iva {
    /* font-size: 9px!important; */
    font-size: 10px;
  }
  .f-responsive-6 {
    /* font-size: 9px!important; */
    font-size: 10px;
  }
  .font-title-mobile {
    font-size: 12px;
  }
  .f-subtitle-mobile {
    font-size: 10px
  }
  .p-mobile {
    /* font-size: 9px; */
    font-size: 10px;
  }
  .img-caracteristicas {
    width: 10px !important;
    height: auto !important;
  }
  .tab-hyundai .ant-tabs-ink-bar {
    height: 6px!important;
  }
  .tab-hyundai .ant-tabs-nav {
    max-height: 50px !important;
  }
  .ant-breadcrumb-separator {
    margin: 0 2px !important;
  }
  .border-breadcrumb {
    border-bottom: 2px solid #f5f4f2;
  }
  .slider-multiple {
    padding: unset !important;
    margin: 0px 40px !important;
  }
  .carousel-indicators {
    margin-bottom: 0 !important;
  }
  .carousel-indicators li {
    width: 20px !important;
    height: 1px !important;
  }
  .slick-prev {
    left: -55px;
  }
  .slick-next {
    right: -55px;
  }
  .ant-drawer-title {
    font-size: 12px !important;
    font-family: "HyundaiSansHead-Bold"!important;
  }
  .f-center-left {
    text-align: left;
  }
  .font-floating-btn {
    /* font-size: 9px !important; */
    font-size: 10px;
  }
  .icon-floating {
    /* max-width: 20px !important; */
    max-height: 20px !important;
  }
  .min-h-floating {
    min-height: 50px;
  }
  .button-mobile-of .ant-btn:active, .button-mobile-of .ant-btn:hover, .button-mobile-of .ant-btn:focus {
    color: #000000 !important;
    border-color: #f5f4f2 !important;
  }
  .drawer-mobile-menu .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
    padding: 5px 0px;
  }
  .drawer-mobile-menu .ant-drawer-mask {
    opacity: 0.6!important;
    background-color: #002a61!important;
  }  
  .font-subsecciones h4 {
    font-size: 1rem;
  }
  
  .font-subsecciones h3 {
    font-size: 1.25rem;
  }
  
  .font-subsecciones h2 {
    font-size: 1.5rem;
  }
  .collapse-subsecciones .ant-collapse-header{
    font-size: 13px!important;
    font-weight: 600 !important;
    border: 1px solid #E8E8E8 !important;
  }
  .collapse-subsecciones .ant-collapse-item{
    border: none !important;
  }
  .collapse-subsecciones .ant-collapse-content{
    /* border: 1px solid #E8E8E8 !important; */
    border-top: 5px solid #35719e !important;
  }
}

.ant-tabs-tab {
  margin: 0 16px !important;
}

.btn-unidad-ficha-right {
  border-top: 1px solid #7D9FBA;
  border-bottom: 1px solid #7D9FBA;
  border-right: 1px solid #7D9FBA;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 30px;
  height: 30px;
  background-color: #FFFFFF;
}

.ant-checkbox-inner {
  border-color: #707070 !important;
  background-color: #FFFFFF !important;
  margin: 5px !important;
  /* margin-left: 0px !important;   */
  width: 20px !important;
  height: 20px !important;
}

.ant-checkbox-inner::after {
  border-color: #000000 !important;
  top: 47% !important;
  left: 25% !important;
}

.ant-checkbox-checked::after {
  border: 1px solid #35719e !important;
  margin: 5px !important;
  /* margin-left: 0px !important;   */
  width: 20px !important;
  height: 20px !important;
}

.ant-radio-inner::after {
  background-color: #35719e !important;
  top: 2px !important;
  left: 2px !important;
  width: 10px !important;
  height: 10px !important;
}

.ant-radio-inner {
  border-color: #000000 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #35719e !important;
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: #35719e !important;
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: #35719e !important;
}

.paginador-hyundai .ant-pagination-item-active:hover a, .paginador-hyundai .ant-pagination-item:focus a {
  color: #FFFFFF !important;
}

.border-top-white-hy {
  border-top: 1px solid #9bb8cf;
}

.border-bottom-seleccion {
  border-bottom: 4px solid #f5f4f2;
}

.border-intermedio {
  border-right: 1px solid #dbe0e4;
}

.ant-slider-handle {
  border-color: #35719e !important;
}

.ant-slider-rail {
  background-color: #b6d5ed !important;
}

.ant-slider-track {
  background-color: #35719e !important;
}

.ant-slider-dot {
  border-color: #35719e !important;
}

.inputModeloCotizador {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border: 2px solid #35719e !important;
  height: 34px;
  width: 200px;
}

.ant-switch-checked {
  background-color: #35719e !important;
}

.slider-lg {
  width: 100% !important;
  height: auto !important;
  max-height: 445px !important;
}

.slider-md {
  width: 100% !important;
  height: auto !important;
  max-height: 365px !important;
}

.img-preview {
  width: 300px !important;
  height: auto !important;
  max-height: 150px !important;
}

.img-preview-rep {
  width: 220px !important;
  height: auto !important;
  max-height: 150px !important;
}

.img-galeria {
  width: 100% !important;
  height: auto !important;
  max-height: 500px !important;
}

.img-destacadas {
  width: 100% !important;
  height: auto !important;
  max-height: 550px !important;
}

.img-especificaciones {
  width: 900px !important;
  height: auto !important;
  max-height: 600px !important;
}

.img-exp-clientes {
  width: 100% !important;
  height: auto !important;
  max-height: 380px !important;
}

@media (max-width: 768px) {
  .img-preview {
    width: 150px !important;
    height: auto !important;
    max-height: 75px !important;
  }
  .slider-lg {
    max-height: 100px !important;
  }
  .slider-md {
    max-height: 85px !important;
  }
  .img-preview-rep {
    max-height: 135px !important;
  }
  .img-galeria {
    max-height: 200px !important;
  }
  .img-destacadas {
    max-height: 200px !important;
  }
  .img-especificaciones {
    width: 100%;
    max-height: 200px !important;
  }
  .img-exp-clientes {
    max-height: 200px !important;
  }
}

@media (min-width: 700px) and (max-width: 1440px) {
  .padding-menu {
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
  }
  .slider-lg {
    max-height: 340px !important;
  }
  .slider-md {
    max-height: 270px !important;
  }
  .min-h-floating {
    min-height: 50px;
  }
  .px-tabs-especificacion {
    margin-top: .5rem!important;
  }
}

.font-hyundai-bold {
  font-family: "HyundaiSansHead-Bold" !important;
}

.bg-hyundai-light {
  background-color: #f6f3f2;
}

.bg-hyundai-commercial-blue {
  background-color: #35719e;
}

div.bg-white {
  height: auto !important;
}

.htmlHyundai p{
  margin-bottom: 0;
}

.ant-rate-star-zero {
  color: #f5f4f2 !important;
}

.ant-rate-star-full{
  color: #35719e !important;
}
