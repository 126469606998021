.title-principal-form{
    font-family: 'HyundaiSansHead-Bold';
    color: #37719F;
    font-size: 22px;
    opacity: 1;
}

.principal-text-form{
    color: #37719F;
    font-family: 'HyundaiSansHead-Regular';
    font-size: 16px;
    opacity: 1;
}

.select-form-hiunday{
    width: 100%;
    
}

.input-error-form{
    border: 1px solid red;
}


.texto-error-input{
    text-align: right;
    margin-top:5px;
    margin-bottom: 0;
    color: #E00303;
    font-family: 'HyundaiSansHead-Regular';
}


.select-form-hiunday .ant-select-selector{
    border: 1px solid #37719F !important;
    opacity: 1;
    border-radius: 5px!important;
}

.select-form-hiunday .ant-select-arrow{
    color: #37719F;
    opacity: 1;
}

.color-text-form{
    color: #37719F;
    opacity: 1;
}

.necesaryCamp-form{
    color: #EB541C;
}

.input-hyundai-style{
    background-color: #E9F0F7;
    color: #37719F;
    font-family: 'HyundaiSansHead-Regular';
    border: 1px;
}

.input-hyundai-style::placeholder{
    color: #37719F;
    opacity: 0.5;
}

.input-hyundai-style:focus {
    border: 1px solid #379FF0;
}

.submit-btn-form{
    background: #4A94CE;
    border-radius: 7px;
    opacity: 1;
    color: #FFFFFF;
    font-size: 22px;
    font-family: 'HyundaiSansHead-Regular';
    height: 53px;
}

.btn-unidad-hy{
    font-family: "HyundaiSansHead-Regular";
    font-weight: 500;
    height: 33px;
    background: #E9F0F7;
    opacity: 1;
    color: #03306C;
    border-color: #E9F0F7;
}

.number-input-hyundai{
    width:50px;
    height: 34px;
    background-color: #4A94CE;
    color: #FFFFFF;
}

.clr-swal{
    color: #000000;
}